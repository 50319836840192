<template>
  <div class="p-2" v-loading="load">
    <el-row :gutter="10" class="mb-2">
      <el-col>
        <h4 class="">
          Bank Transfer
          <img src="/images/app/abank.svg" alt="bank" style="width: 80px;margin-bottom:8px;"/>
        </h4>
      </el-col>
      <el-col :span="6" :xs="24">
        <label style="line-height: 35px;">Amount(UAH)</label>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-input style="width: 100%;"
                  placeholder="Please input amount"
                  type="number" v-model.trim.number="amount"
                  :pattern="[0-9]"
                  @change="numberChange(arguments[0],10000)"
                  :controls="false" :step="1">
          <template slot="append">UAH</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row class="mb-2">
      <el-alert type="warning" show-icon :closable="false"
                title="Please transfer to the right account, then upload your receipt">
      </el-alert>
      <div class="col-md-12 mt-2 pt-0">
        <div class="card border-0 rounded shadow bg-light">
          <div class="card-body" style="background-color: papayawhip; border-radius: 0.25rem; color: black;">
            <h5 class="para">
              Bank Information
            </h5>
            <div class="para">
              <span class="label">Bank:</span>
              <span class="value">{{ this.bankInfo.bank }}</span>
            </div>
            <div class="para">
              <span class="label">Holder Name:</span>
              <span class="value">
              {{ this.bankInfo.holderName }}
              </span>
              <span class="copy-button" v-clipboard:copy="this.bankInfo.holderName" v-clipboard:success="copysuccess" v-clipboard:error="copyerror">copy</span>
            </div>
            <div class="para">
              <span class="label">Account Number:</span>
              <span class="value">
              {{ this.bankInfo.accountNumber }}
              </span>
              <span class="copy-button" v-clipboard:copy="this.bankInfo.accountNumber" v-clipboard:success="copysuccess" v-clipboard:error="copyerror">copy</span>
            </div>
<!--            <div class="para" v-if="this.bankInfo.remark!=='' && this.bankInfo.remark !== null">-->
<!--              <span class="label">Remark:</span>-->
<!--              <span class="value">-->
<!--              {{ this.bankInfo.remark }}-->
<!--              </span>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </el-row>
    <el-row :gutter="10" class="mb-2">
      <el-col :span="6" :xs="24">
        <label style="line-height: 35px;">Attach Payment Receipt</label>
      </el-col>
      <el-col :span="18" :xs="24">
        <b-form-file style="width:100%" v-model="data.voucher" :state="Boolean(data.voucher)"
                     placeholder="Upload Receipt"
                     drop-placeholder="Upload Receipt" browse-text="Select">
        </b-form-file>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;" v-if="alert.show">
      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
    </el-row>
    <el-row :gutter="10" class="mb-2">
      <el-col :span="4">
        <b-button type="primary" @click="deposit">Submit</b-button>
      </el-col>
    </el-row>

  </div>
</template>


<script>
import walletApi from '../../../apis/walletApi';

export default {
  data() {
    return {
      load: false,
      amount: "",
      alert: {
        text: "",
        show: false
      },
      data: {
        amount: "",
        currency: "INR",
        voucher: undefined
      },

    }
  },
  props:{
    bankInfo: Object
  },
  created() {

  },
  methods: {
    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    },
    numberChange(val,maxNum){
      this.amount = Number(val)
      this.$nextTick(()=>{
        let num = Math.min(Number(val),maxNum)
        if (!Number.isInteger(num) || num < 0) {
          this.amount = 0;
        } else {
          // this.amount = num;
        }
      })
    },
    deposit() {
      // date
      const currentUTCHour = new Date().getUTCHours();
      const isNightTime = currentUTCHour >= 7 && currentUTCHour < 18;
      if(!isNightTime){
        this.alert.show = true
        this.alert.text = "Канал депозитів UAH в режимі обслуговування"
        return
      }
      if (this.amount === "") {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      if(this.amount < 200){
        this.alert.show = true
        this.alert.text = "Minimum amount is 200 UAH."
        return
      }
      if(this.amount > 10000){
        this.alert.show = true
        this.alert.text = "Maximum amount is 10000 UAH."
        return
      }
      if (this.data.voucher === "" || this.data.voucher === undefined) {
        this.alert.show = true
        this.alert.text = "Receipt cannot be empty"
        return
      }
      this.data.amount = this.amount
      walletApi.ukraineBankDeposit(this.data, res => {
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Pending..")
          this.alert.show = false
          this.data = {
            amount: "",
            voucher: undefined
          }
          this.amount=''
        }
      })
    },
  }

}
</script>


<style>

.para {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  width: 150px;
  margin-right: 10px;
  font-weight: bold;
}

.value {
  flex-grow: 1;
}

.copy-button {
  margin-left: 10px;
  color: darkorange;
}

@media only screen and (max-width: 800px) {
  .para {
    font-size: 15px;
  }
  .label {
    width: 80px;
    font-size: 15px;
    margin-right: 5px;
  }
  .value {
    font-size: 15px;
  }
  .copy-button {
    margin-left: 5px;
  }
}

</style>
