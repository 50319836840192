<template>
  <div class="p-2" v-loading="load">
    <el-row :gutter="10" class="mb-2">
      <el-col>
        <h4 class="text-primary">
          2. Debit Or Credit Card
          <img src="/images/app/Visacard.png" alt="visa" style="width: 50px;"/>
          <img src="/images/app/Mastercard.png" alt="master" style="width: 50px;"/>
        </h4>
      </el-col>
      <el-col :span="6" :xs="24">
        <label style="line-height: 35px;">Amount(USD)</label>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-input style="width: 100%;"
                  placeholder="Please input amount"
                  type="number" v-model.trim.number="amount"
                  :pattern="[0-9]"
                  @change="numberChange(arguments[0],5000)"
                  :controls="false" :step="1">
          <template slot="append">$</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;" v-if="alert.show">
      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
    </el-row>
    <el-row :gutter="10" class="mb-2">
      <el-col :span="4">
        <b-button type="primary" @click="deposit">Submit</b-button>
      </el-col>
    </el-row>

  </div>
</template>


<script>
import walletApi from '../apis/walletApi';

export default {
  data() {
    return {
      load: false,
      amount: "",
      alert: {
        text: "",
        show: false
      },
      data: {
        amount: "",
        currency: "USD",
      },


    }
  },
  created() {

  },
  methods: {
    numberChange(val,maxNum){
      this.amount = Number(val)
      this.$nextTick(()=>{
        let num = Math.min(Number(val),maxNum)
        if (!Number.isInteger(num) || num < 0) {
          this.amount = 0;
        } else {
          this.amount = num;
        }
      })
    },
    deposit() {
      if (this.amount === "") {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      if(this.amount < 50){
        this.alert.show = true
        this.alert.text = "The minimum amount is 50"
        return
      }
      this.data.amount = this.amount
      walletApi.creditDeposit(this.data, res => {
        this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          //this.$message.success("Success, your order is pending..")
          console.log("get paymentLink:"+ res.data)
          this.alert.show = false
          this.amount = ''
          window.location.href= res.data
        }
      })
    },
  }

}
</script>


<style>


</style>
