<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import walletApi from "@/apis/walletApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import Navbar2 from "../components/navbar2.vue";
import Withdrawal from "../components/withdrawal.vue";
import Recharge from "../components/recharge.vue";
import Modification from "../components/modification.vue";
import HistoryDeposit from './history-deposits.vue';
import Back from "../components/back.vue";
import CreditCard from '../components/creditCard.vue';
import IndiaUPIPay from '../components/indiaUPIPay.vue';
import IndiaBank from '../components/indiaBank.vue';
import ABank from '../views/new-view/components/ABank.vue';

/**
 * Index-1
 */
export default {
  data() {
    return {
      wallet: undefined,
      walletLoad: 0,
      login: false,
      walletForm: {
        name: "",
        pageNum: 1,
        pageSize: 8
      },
      index: 1,
      bankShow: false,
      bankInfo: undefined
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2,
    Withdrawal,
    Recharge,
    Modification,
    HistoryDeposit,
    Back,
    CreditCard,
    IndiaUPIPay,
    IndiaBank,
    ABank
  },
  created() {
    //获取项目列表
    walletApi.balance(res => {
      this.wallet = res.data
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }

    // ua
    const isUkraine = navigator.language.toLowerCase() === 'uk-ua' || navigator.language.toLowerCase() === 'uk' || navigator.language.toLowerCase() === 'ru-ru'
    const currentUTCHour = new Date().getUTCHours()
    const isNightTime = currentUTCHour >= 7 && currentUTCHour < 18
    // console.log(navigator.language.toLowerCase())
    // console.log('isUkraine:' + isUkraine + '   isNightTime:' + isNightTime + currentUTCHour)
    if (isUkraine && isNightTime) {
      walletApi.getUkraineBank(res => {
        if (res.data != null && res.data.show === true) {
          this.bankShow = true
          this.bankInfo = res.data
        }
      })
    }


  },
  methods: {
    loadmore(page) {
      //开始加载
      this.walletLoad = 1
      this.walletForm.pageNum = page
      //获取项目列表
      walletApi.list(this.walletForm, res => {
        this.walletLoad = 0
        Object.assign(this.wallet, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.walletForm.pageNum++
      //开始加载
      this.walletLoad = 1
      let pages = Math.ceil(this.wallet.totalCount / this.walletForm.pageSize)
      //获取项目列表
      if (pages >= this.walletForm.pageNum) {
        walletApi.list(this.walletForm, res => {
          res.data.result.unshift(...this.wallet.result)
          Object.assign(this.wallet, res.data)
          this.walletLoad = 0
          if (pages <= this.walletForm.pageNum) this.walletLoad = -1//结束加载
        })
      } else {
        this.walletLoad = -1;
      }
    },
    indexChange(index) {
      this.index = index;
    }
  },
};
</script>

<template>
  <div style="margin-bottom:50px;">
    <!-- 项目列表 -->
    <section class="section" style="padding-bottom: 0px;margin-top: -100px;z-index:5;">
      <back/>
      <div class="container">
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="14" :xs="24">-->
        <!--            <h4>Deposit</h4>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row :gutter="20" class="mt-4">
          <el-col :span="24">
            <el-row :gutter="10" class="mb-2">
              <el-col>
                <h4>Deposit</h4>
                <!--                <p style="color:orange;">We offer the following payment methods.</p>-->
              </el-col>
            </el-row>
            <div class="card p-2 mt-2 border-0 shadow bg-light">
              <Recharge v-if="index === 1"></Recharge>
              <Withdrawal v-else-if="index === 2"></Withdrawal>
              <Modification v-else></Modification>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <!-- VISA   -->
    <!--    <section class="section" style="padding-bottom: 0px;margin-top: -50px;z-index:4;">-->
    <!--      <div class="container">-->
    <!--        <div class="card p-2 mt-2 border-0 shadow bg-light">-->
    <!--          <CreditCard></CreditCard>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!-- India UPI -->
    <!--    <section class="section" style="padding-bottom: 0px;margin-top: -50px;z-index:3;">-->
    <!--      <div class="container">-->
    <!--        <div class="card p-2 mt-2 border-0 shadow bg-light">-->
    <!--          <IndiaUPIPay></IndiaUPIPay>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!-- India bank -->
    <!--    <section class="section" style="padding-bottom: 0px;margin-top: -50px;z-index:2;" v-if="bankShow">-->
    <!--      <div class="container">-->
    <!--        <div class="card p-2 mt-2 border-0 shadow bg-light">-->
    <!--          <IndiaBank :bankInfo="this.bankInfo"></IndiaBank>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!-- ukraine bank-->
    <section class="section" style="padding-bottom: 0px;margin-top: -50px;z-index:2;" v-if="bankShow">
      <div class="container">
        <div class="card p-2 mt-2 border-0 shadow bg-light">
          <ABank :bankInfo="this.bankInfo"></ABank>
        </div>
      </div>
    </section>
    <section style="padding-bottom: 50px;z-index:1;">
      <HistoryDeposit></HistoryDeposit>
    </section>

  </div>
</template>
